import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../components/layout";
import { createGlobalStyle } from "styled-components";
import { Clock } from "../../projects/colour-clock/clock";

const GlobalStyle = createGlobalStyle`
  main {
    background: ${(p) => p.background || "unset"}
  }
`;

const Description = styled.p`
  opacity: 0.38;
`;

const calcHsl = (progress, normalizationFactor) => {
  const luminanceProgress = progress * 100 * normalizationFactor;
  const weightedLuminance = Math.floor(luminanceProgress);

  const saturationProgress =
    (luminanceProgress - weightedLuminance) * 100 * normalizationFactor;
  const weightedSaturation = Math.floor(saturationProgress);

  const hueProgress = (saturationProgress - weightedSaturation) * 360;
  const hue = Math.floor(hueProgress);

  return {
    hue,
    saturation: weightedSaturation / normalizationFactor,
    luminance: weightedLuminance / normalizationFactor,
  };
};

const CenteredSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: ${(p) =>
    p.luminance > 50 ? "rgba(0, 0, 0, 0.87)" : "rgba(255, 255, 255, 0.87)"};
  p,
  h1,
  h2,
  h3 {
    color: inherit;
  }
`;

const ColourClock = () => {
  const [state, setState] = useState({
    time: null,
    hue: null,
    saturation: null,
    luminance: null,
  });

  const currentYear = new Date().getFullYear();
  const currentNewYearTimestamp = new Date(currentYear, 0, 1).getTime();
  const nextNewYearTimestamp = new Date(currentYear + 1, 0, 1).getTime();
  const normalizationFactor =
    Math.sqrt((nextNewYearTimestamp - currentNewYearTimestamp) / 1000 / 360) /
    100;

  useEffect(() => {
    let timeoutId;
    const run = () =>
      window.setTimeout(() => {
        const time = new Date();
        const progress =
          (time.getTime() - currentNewYearTimestamp) /
          (nextNewYearTimestamp - currentNewYearTimestamp);
        const { hue, saturation, luminance } = calcHsl(
          progress,
          normalizationFactor
        );
        setState({ time, hue, saturation, luminance });
        timeoutId = run();
      }, 1000 - (Date.now() % 1000));
    timeoutId = run();

    return () => {
      window.clearTimeout(timeoutId);
    };
  });

  const { time, hue, saturation, luminance } = state;

  return (
    <Layout
      title="Colour Clock"
      keywords={["colour clock"]}
      description="A different colour "
    >
      {time !== null ? (
        <CenteredSection luminance={luminance}>
          <GlobalStyle
            background={`hsl(${hue}, ${saturation.toFixed(
              1
            )}%, ${luminance.toFixed(1)}%)`}
          />
          <Clock time={time} />
          <Description>
            This clock will cycle through all the RGB colors in one year,
            resetting at midnight on January 1<sup>st</sup>.
          </Description>
        </CenteredSection>
      ) : null}
    </Layout>
  );
};

export default ColourClock;
