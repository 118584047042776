import React from "react";
import styled from "styled-components";

const Date = styled.h2``;

const Time = styled.h1`
  font-size: 16.9vw;
  font-weight: 100;
  margin: 0;
`;

const Zone = styled.h3``;

export const Clock = ({ time, luminance }) => {
  const dateFormat = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const timeFormat = new Intl.DateTimeFormat(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const zoneFormat = new Intl.DateTimeFormat(undefined, {
    timeZoneName: "short",
  });
  return (
    <>
      <Date luminance={luminance}>{dateFormat.format(time)}</Date>
      <Time luminance={luminance}>{timeFormat.format(time)}</Time>
      <Zone luminance={luminance}>
        {zoneFormat.formatToParts(time).pop().value}
      </Zone>
    </>
  );
};
